import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
};

const CryptoChart = ({borderWidth = 1, data}) => {
  const chartRef = useRef(null);
    const labels = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
        23, 24,
    ];

    const [chartData, setChartData] = useState({
      datasets: [],
    });

    useEffect(() => {
      const dataChart = {
        labels,
        datasets: [
          {
            label: "Dataset 1",
            data: labels.map(() => Math.random() * 1000),
            borderColor: data.percent_change_24h > 0 ? "#00FF00" : "#FF0000",
            backgroundColor: createGradient(chartRef.current.ctx, chartRef.current.chartArea, data.percent_change_24h > 0),
            tension: borderWidth * 0.3,
            borderWidth: borderWidth,
            fill: true
          }
        ],
      };
      setChartData(dataChart)
    }, [])

    

    function createGradient(ctx, area, isUp) {
      const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    
      gradient.addColorStop(0, isUp ? 'rgba(0, 255, 0, 0)' : 'rgba(255, 0, 0, 0)');
      gradient.addColorStop(0.7, isUp ? 'rgba(0, 255, 0, 0.3)' :  'rgba(255, 0, 0, 0.5)');
      gradient.addColorStop(1, isUp ? 'rgba(0, 255, 0, 1)' : 'rgba(255, 0, 0, 1)');
    
      return gradient;
    }

    return <Line ref={chartRef} options={options} data={chartData} />;
}
export default CryptoChart;