import { Col, Container, Row } from "react-bootstrap"
import "./styles.scss"
import LangdingBg from "../../assets/images/BgLanding.svg"
import LangdingBgEx from "../../assets/images/BgLandingEx.svg"
import LandingLinearBg from "../../assets/images/LandingLinearBg.svg"
import { useRef, useState } from "react"
import axios from "axios"

const ComingSoon = () => {
    const txtEmail = useRef()
    const [validEmail, setValidEmail] = useState(true)
    const [showSucessText, setShowSuccessText] = useState(false)

    const onSubmit = function (){
        const email = txtEmail.current
        const rs = validateEmail(email)
        setValidEmail(rs)


        if(rs){
            axios.post(`https://prod-03.uksouth.logic.azure.com:443/workflows/3b0b7b9b09ed47d4afc178962604746e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SOWl8QESXPrYHhskNLYTkqmh_G0gxkzvxKjLoPnYXfo&email=${email.value}`).then(rs => {
                setShowSuccessText(true)
            })
        } else{
            setShowSuccessText(false)
        }
    }
    
    function validateEmail(inputText)
    {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(inputText.value.match(mailformat))
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    return <>
        <section className="box-wind-coming-section no-1">
            <Container className="z-[2] relative">
                <Row>
                    <Col md={6} sm={12} className="text-start">
                        <div className="landing-title">
                            Simple,
                            <br />
                            on the spot
                            <br />
                            <span className="text-bg-linear">Crypto</span>
                            {" "}trading
                        </div>
                        <div className="landing-coming-soon">
                            COMING SOON 
                        </div>
                        <div className="landing-ex-text">
                            Boxwind will offer easy crypto trading with hassle-free onboarding, instant spot trading and secure asset storage.
                            Enter your details below, and we'll let you know when we're going live.
                        </div>
                        <div className="mt-[28px] group-contact-container">
                            <button className="shared-animation-button !py-[16.5px] !px-[54px]" onClick={onSubmit}>
                                <span>
                                    yes, notify me
                                </span>
                            </button>
                            <input className={!validEmail ? 'invalid' : ''} ref={txtEmail} placeholder="Email Address" />
                        </div>
                        { !validEmail && <div className="text-invalid">Invalid email</div> }
                        { showSucessText && <div className="text-success">Registered successfully</div> }
                        {/* <a href="https://trade.boxwind.com">
                            <button className="shared-animation-button mt-[31.5px] !py-[16.5px] !px-[54px]">
                                <span>
                                    START TRADING
                                </span>
                            </button>
                        </a> */}
                    </Col>
                    <Col md={6} sm={12} className="langding-bg">
                        <div className="relative langding-bg-container">
                            <img alt="" src={LangdingBg} className="langding-bg"/>
                            <img alt="" src={LangdingBgEx} className="ex-langding-bg"/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img src={LandingLinearBg} alt="" className="landing-linear-bg"/>
        </section>
    </>
}
export default ComingSoon