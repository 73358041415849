import { Outlet } from "react-router-dom";
import "./styles.scss";
import Logo from "../../assets/images/Logo.svg";
import { useEffect, useRef, useState } from "react";
import BulletListIcon from "../../assets/images/bullet-list-icon.png"
const LayoutComingSoon = () => {
  const navRef = useRef()
  const [blockNav, setBlockNav] = useState(false)

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = (e) => {
    let rect = document.body.getBoundingClientRect()
    if(rect.top < -40){
      setBlockNav(true)
    } else {
      setBlockNav(false)
    }
  }

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-white fixed-top ${blockNav ? 'shrink' : ''}`}
        id="banner"
        ref={navRef}
      >
        <div className="container py-[21px] gap-[42px]">
          <a className="navbar-brand" href="#">
            <span>
              <img draggable="false" src={Logo} alt="logo" className="relative top-[-5px]" />
            </span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <img src={BulletListIcon} alt="" className="w-[40px] h-[40px]" />
          </button>
          <div
            className="collapse navbar-collapse text-white boxwind-nav-coming-soon justify-between"
            id="navbarSupportedContent"
          >
            <div className="navbar-nav">
              <span className="nav-item">
                <a className="nav-link" href="#coming-soon">
                  Coming soon
                </a>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default LayoutComingSoon;
