import logo from "./logo.svg";
import "./App.css";
import "./assets/scss/shared.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./layouts/layout/Layout";
import Home from "./pages/home/Home";
import LayoutComingSoon from "./layouts/layoutComingSoon/LayoutComingSoon";
import ComingSoon from "./pages/comingSoon/ComingSoon";

const isLandingPageOn = process.env.REACT_APP_LANDING_PAGE === "true";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {isLandingPageOn ? (
            <Route path="/" element={<LayoutComingSoon />}>
              <Route index element={<ComingSoon />} />
            </Route>
          ) : (
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
            </Route>
          )}
          <Route path="/landingpage" element={<LayoutComingSoon />}>
            <Route index element={<ComingSoon />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
