import axios from "axios";

export const baseUrl = "https://www.herepreview.top:444/";
// export const baseUrl = "http://localhost:5000/";
export const baseUploadUrl = baseUrl + 'upload/'

axios.defaults.baseURL = baseUrl;
axios.defaults.headers = {
  "Content-Type": "application/json"
}

axios.interceptors.request.use(
(request) => {
  var config = {}
  if (request.config) {
    config = request.config
  }
  config.start = Date.now()
  request.config = config;
  return request
})

export default axios